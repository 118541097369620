import { type HTMLAttributes } from 'react'
import Footer from '../Footer/Footer'
import styles from './Page.module.css'

type Props = {
  /** Whether or not to render the footer */
  footer?: boolean
} & HTMLAttributes<HTMLDivElement>

export default function Page ({
  children,
  footer = true,
  className = '',
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${styles.page} ${className}`}
    >
      {children}
      {footer && (
        <Footer />
      )}
    </div>
  )
}
